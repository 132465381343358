import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { NewHousebanDialog } from "./NewHousebanDialog";

import './Housebanlist.css';


export function Housebanlist(){
	
	const [ loading, setLoading ] = useState(true);
	const [ housebannedpersons, setHousebannedpersons ] = useState([]);
	
	const [ newHousebanOpen, setNewHousebanOpen ] = useState(false);
	
	let { id } = useParams()
	
	const mailaddress = useSelector(state => state.usermangement.mailaddress);
	const password = useSelector(state => state.usermangement.password);
	
	const loadHousebanned = useCallback(() => {
		
		const toastid = toast.loading('Loading data ...');
		
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Basic ' + btoa(mailaddress + ":" + password));

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		
		fetch(window._env_.URL + "/api/v1/housebanned/" + id, requestOptions)
			.then(response => response.text())
			.then((result) => {
				
				setHousebannedpersons(JSON.parse(result));
				
				setLoading(false);
				
				toast.update(toastid, { render: "Data loaded", type: "success", isLoading: false, autoClose: 5000 })
				
			})
			.catch((err) => { 
				
				console.log(err)
				
				setLoading(false);
				
				toast.update(toastid, { render: err, type: "error", isLoading: false, autoClose: 5000 });
				
			})
			
	}, [mailaddress, password, id])
	
	useEffect(() => {
		loadHousebanned();
	}, [loadHousebanned]);
	
	function addHousebanned(){
		setNewHousebanOpen(true);
	}
	
	function saveNewhousebanned(person){
		
		if(person === null){
			setNewHousebanOpen(false);
		} else {
			
			const toastid = toast.loading('Saving house banned person ...');
			
			person.store = parseInt(id);
		
			var myHeaders = new Headers();
			myHeaders.append('Authorization', 'Basic ' + btoa(mailaddress + ":" + password));

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: JSON.stringify(person),
				redirect: 'follow'
			};
		
			fetch(window._env_.URL + "/api/v1/housebanned", requestOptions)
				.then(response => response.text())
				.then((result) => {
				
					if(result.trim() === "OK")	{
						
						setNewHousebanOpen(false);
					
						loadHousebanned();
				
						toast.update(toastid, { render: "House banned person saved", type: "success", isLoading: false, autoClose: 5000 })
					
					} else {
						toast.update(toastid, { render: "Error", type: "error", isLoading: false, autoClose: 5000 });
					}
				
				
				})
				.catch((err) => { 
				
					console.log(err)
				
					toast.update(toastid, { render: err, type: "error", isLoading: false, autoClose: 5000 });
				
				})
			
		}
		
	}
	
	return (
		<>
			{ loading &&
				<p>Lade Daten ...</p>
			}
			{ !loading &&
				<>
					<h1>List of house banned persons</h1>
					{ housebannedpersons.length > 0 &&
						<table>
							<tr><th>Bild</th><th>name</th></tr>
							{ housebannedpersons.map((person) => (
								<tr>
									<td>
										{person.images.length > 0 &&
											<img className="housebannedperson" alt="banned person" src={person.images[0].image} />
										}
									</td>
									<td>{person.name}</td>
								</tr>
							))}
						</table>
					}
					{housebannedpersons.length === 0 &&
						<p>At this time noone is house banned</p>
					}
					<div className="btnspandiv"><span className="btnspan" onClick={addHousebanned}>Add house banned person</span></div>
					<NewHousebanDialog
						open={newHousebanOpen}
						onClose={saveNewhousebanned} />
				</>
			}
		</>
	);
	
}