import React, {useState} from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { setLoggedIn, setMailAddress, setPassword } from "../features/usermanagement/usermanagementSlice";

export function Login() {
	
	const dispatch = useDispatch();

	const [ mailaddress, setMailaddress ] = useState("");
	const [ pwd, setPwd ] = useState("");
	
	function loginTry(){
		
		const toastid = toast.loading('Login ...');
		
		var headers = new Headers();
		headers.append('Authorization', 'Basic ' + btoa(mailaddress + ":" + pwd));

		var requestOptions = {
  			method: 'GET',
  			headers: headers,
  			redirect: 'follow'
		};
		
		fetch(window._env_.URL + "/api/v1/checklogin", requestOptions)
			.then((response) => {
				if (response.status === 200) {

					dispatch(setLoggedIn(true));
					dispatch(setMailAddress(mailaddress));
					dispatch(setPassword(pwd));
  			
  					toast.update(toastid, { render: "Login erfolgreich", type: "success", isLoading: false, autoClose: 5000 });
  				
  				} else {
					
					dispatch(setLoggedIn(false));
					
					toast.update(toastid, { render: "Falsche Zugangsdaten", type: "error", isLoading: false, autoClose: 5000 });
					
				}
			})
			.catch((err) => {
				console.log(err)
				dispatch(setLoggedIn(false));
				toast.update(toastid, { render: err, type: "error", isLoading: false, autoClose: 5000 });
			})
		
	}
	
	return (
		<>
			<h1>Login</h1>
			<input placeholder="mail address" value={mailaddress} onChange={e => setMailaddress(e.target.value)} /><br />
			<input placeholder="password" type="password" value={pwd} onChange={e => setPwd(e.target.value)} /> <br />
			<div className="btnspandiv"><span className="btnspan" onClick={loginTry}>Log in</span></div>
		</>
	);
	
}