import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export function StoreList(){
	
	const [ loading, setLoading ] = useState(true);
	const [ stores, setStores ] = useState([]);
	
	const mailaddress = useSelector(state => state.usermangement.mailaddress);
	const password = useSelector(state => state.usermangement.password);
	
	useEffect(() => {
		
		const toastid = toast.loading('Loading data ...');
		
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Basic ' + btoa(mailaddress + ":" + password));

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		
		fetch(window._env_.URL + "/api/v1/stores", requestOptions)
			.then(response => response.text())
			.then((result) => {
				
				setStores(JSON.parse(result));
				
				setLoading(false);
				
				toast.update(toastid, { render: "Data loaded", type: "success", isLoading: false, autoClose: 5000 })
				
			})
			.catch((err) => { 
				
				console.log(err)
				
				setLoading(false);
				
				toast.update(toastid, { render: err, type: "error", isLoading: false, autoClose: 5000 });
				
			})
		
	}, [mailaddress, password])
	
	return(
		<>
			{ loading &&
				<p>Lade Daten ...</p>
			}
			{ !loading &&
				<ul>
					{stores.map((store) => (
						<li><Link to={"/" + store.id}>{store.name}</Link></li>
					))}
				</ul>
			}
		</>
	);
	
}