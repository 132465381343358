import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	mailaddress: "",
	password: "",
	loggedIn: false	
};

export const usermanagementSlice = createSlice({
	
	name: 'usermanagement',
	initialState,
	reducers: {
		setMailAddress: (state, mailaddress) => {
			state.mailaddress = mailaddress.payload;
		},
		setPassword: (state, password) => {
			state.password = password.payload;
		},
		setLoggedIn: (state, status) => {
			state.loggedIn = status.payload;
		},
		logout: (state) => {
			state.loggedIn = false;
		}
	},	
});

export const {setMailAddress, setPassword, setLoggedIn, logout} = usermanagementSlice.actions;

export default usermanagementSlice.reducer;